
import store from '../../../store'
export default {
  setup() {
    const user = store.state.User
    return {
      user
    }
  }
}
